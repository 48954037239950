import React, { useState, useEffect } from "react";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import {
  getFoodItemQuantity,
  getOrderById,
  getFoodItem,
} from "../../../services/apiBaseService";
import { Watch } from "react-loader-spinner";
import {useSignalR} from "../../../services/useSignalR";
import useSubject from "../../../hooks/useSubject";
import {orderStatusSubject} from "../../../subjects/custome-subject";
import {environment} from "../../../environments/environment";

const OrderStatus = () => {
  const device = useDeviceDetect();
  const [order, setOrder] = useState(null);
  const [quantityTagValue, setQuantityTagValue] = useState([]);
  const [food, setFood] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderStatus] = useSubject(orderStatusSubject.getOrderStatus());
  useSignalR( environment.signalRUrl);

  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("orderId");

  useEffect(() => {
    getFoodItemQuantityTags();
    getAllFoods();
    getOrderByIdAsync(orderId);
  }, [orderId]);

    useEffect(() => {
        if (orderStatus && orderStatus.orderId == orderId) {
            getOrderByIdAsync(orderStatus.orderId);
        }
    }, [orderStatus]);

  const getOrderByIdAsync = async (orderId) => {
    try {
      setIsLoading(true); // Set loading state to true
      const response = await getOrderById(orderId);
      if (response && response.length > 0) {
        setOrder(response[0]);
      }
    } catch (error) {
      console.error("Error fetching order by id", error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const getFoodItemQuantityTags = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const response = await getFoodItemQuantity();
      setQuantityTagValue(response);
    } catch (error) {
      console.error("Error fetching quantity tags", error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const getAllFoods = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const response = await getFoodItem();
      setFood(response);
    } catch (error) {
      console.error("Error fetching quantity tags", error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const getQuantityTagName = (id, quantityTags) => {
    const tag = quantityTags.find((tag) => tag.id === id);
    return tag ? tag.name : "";
  };

  const getFoodName = (id, foodName) => {
    const food = foodName.find((tag) => tag.id === id);
    return food ? food.name : "";
  };

  const getOrderStatusString = (status) => {
    switch (status) {
      case 1:
        return "Placed";
      case 2:
        return "Preparing";
      case 3:
        return "Released From Kitchen";
      case 4:
        return "Served";
      case 5:
        return "Closed";
      default:
        return "Unknown";
    }
  };

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {isLoading ? (
        <div className="loader-overlay">
          <Watch
            visible={true}
            height="80"
            width="80"
            radius="48"
            color="#4fa94d"
            ariaLabel="watch-loading"
          />
        </div>
      ) : device?.isMobile ? (
        <div>
          <div className="text-center mt-2">
            <img
              src="../../assets/CompanyLogo.png"
              alt="avatar"
              style={{ height: "50px" }}
            />
            <div className="mt-2 h4">Your Order Status</div>
          </div>
          <div className="d-flex text-center m-3 justify-content-between align-items-center">
            <div className="h6">{order?.cartResponseDetails?.length} Items</div>
            <div className="h6" style={{ color: "#640000" }}>
              {getOrderStatusString(order.orderingStatus)}{" "}
            </div>
          </div>
          <div>
            {order?.cartResponseDetails?.map((foodItem, i) => (
              <div className="row m-1" key={i}>
                <div className="col-3">
                  <div>
                    {foodItem.quantity} <span className="ms-3">x</span>
                  </div>
                  <small>
                    <i>
                      {getQuantityTagName(
                        foodItem.foodQuantityTagId,
                        quantityTagValue
                      )}
                    </i>
                  </small>
                </div>
                <div className="col-9">
                  <div className="h7 text-weight">
                    {" "}
                    {getFoodName(foodItem.foodId, food)}
                  </div>
                  <div className="h7">{foodItem.kitchenIntroduction}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "40%" }}>
            <div className="text-center mt-2">
              <img
                src="../../assets/CompanyLogo.png"
                alt="avatar"
                style={{ height: "50px" }}
              />
              <div className="mt-2 h4">Your Order Status</div>
            </div>
            <div className="d-flex text-center m-3 justify-content-between align-items-center">
              <div className="h6">{order.cartResponseDetails.length} Items</div>
              <div className="h6" style={{ color: "#640000" }}>
                {getOrderStatusString(order.orderingStatus)}{" "}
              </div>
            </div>
            <div>
              {order?.cartResponseDetails?.map((foodItem, i) => (
                <div className="row m-1" key={i}>
                  <div className="col-3">
                    <div>
                      {foodItem.quantity} <span className="ms-3">x</span>
                    </div>
                    <small>
                      <i>
                        {getQuantityTagName(
                          foodItem.foodQuantityTagId,
                          quantityTagValue
                        )}
                      </i>
                    </small>
                  </div>
                  <div className="col-9">
                    <div className="h7 text-weight">
                      {" "}
                      {getFoodName(foodItem.foodId, food)}
                    </div>
                    <div className="h7">{foodItem.kitchenIntroduction}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderStatus;
