import React, { useState } from "react";
import useDeviceDetect from "../hooks/useDeviceDetect";
import NavBar from "../components/LandingPage/NavBar/NavBar";
import LandingFooter from "../components/LandingPage/Footer/LandingFooter";
import PopularDeals from "../components/LandingPage/PopularDeals/PopularDeals";
import Content from "../components/LandingPage/Content/Content";

export default function LandingPage() {
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const device = useDeviceDetect();

  const handleMenuClick = (menuId) => {
    setSelectedMenuId(menuId);
    const contentSection = document.getElementById("content-section");
    if (contentSection) {
      contentSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <NavBar />
      <div id="content-section">
        <Content selectedMenuId={selectedMenuId} />
      </div>
      <PopularDeals />
      <LandingFooter onMenuClick={handleMenuClick} />
    </div>
  );
}
