import React, { useState } from "react";
import ProductCard from "../components/ProductCard/productCard";
import SearchBar from "../components/SearchBar/SearchBar";
import TabButtons from "../components/TabButtons/TabButtons";
import SideBar from "../components/SideBar/SideBar";
import useDeviceDetect from "../hooks/useDeviceDetect";
import "../App.css";
import Footer from "../components/Footer/Footer";
import { Modal } from "antd";
import TableModel from "../components/TableModel/TabelModel";
import ActiveProduct from "../components/PlaceOrder/ActiveProduct/ActiveProduct";

export default function HomePage() {
  const device = useDeviceDetect();

  const [selectedOption, setSelectedOption] = useState("Dine-in");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const DineInOk = () => {
    setIsModalOpen(false);
    setSelectedOption("");
  };

  const DineInCancel = () => {
    setIsModalOpen(false);
    setSelectedOption("");
  };

  return (
    <div>
      {device?.isMobile ? (
        <>
          <div className="  align-items-center pt-1 pb-1 ps-3 pe-3">
            <div className="d-flex justify-content-between align-items-center">
              <img
                src="../../assets/CompanyLogo.png"
                alt="avatar"
                style={{
                  height: "50px",
                }}
              />
              <div
                className="ms-3"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Sign In
              </div>
            </div>
          </div>
          <div className="row mt-4">
                <ActiveProduct/>
            </div>
        </>
      ) : (
        <>
          <div className="ps-3 pe-3">
            <div className=" align-items-center pt-1 pb-1">
              <div className="d-flex justify-content-between align-items-center">
                <img
                  src="../../assets/CompanyLogo.png"
                  alt="avatar"
                  style={{
                    height: "80px",
                  }}
                />
                <div className="col-10">
                  <SearchBar />
                </div>
              </div>
            </div>
            <div className="row mt-4">
                <ActiveProduct/>
            </div>
          </div>
        </>
       
      )}

      {/* <Modal title="Select the Dining table" visible={selectedOption === 'Dine-in'} onOk={DineInOk} onCancel={DineInCancel}>
                <TableModel />
            </Modal> */}
    </div>
  );
}
