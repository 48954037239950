import useDeviceDetect from "../../../../hooks/useDeviceDetect";
import React, {useEffect, useState} from "react";
import {Scanner} from "@yudiel/react-qr-scanner";
import {Button, Form, Input, InputNumber, Modal} from "antd";
import {IDetectedBarcode} from "@yudiel/react-qr-scanner";
import {useDispatch} from "react-redux";
import {addUserDetails} from "../../../../store/PlaceOrderActions";
import {useNavigate} from "react-router-dom";

export default function DineIn({seatedTableId}) {
    const device = useDeviceDetect();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [tableId, setTableId] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isScannerOpen, setIsScannerOpen] = useState(false);
    const [isScannerClicked, setIsScannerClicked] = useState(false);

    const showModal = (result: IDetectedBarcode[]) => {
        const urlObject = new URL(result[0].rawValue);
        const queryParams = new URLSearchParams(urlObject.search);
        const tableId = queryParams.get("tableId");
        if (tableId !== null){
        setTableId(tableId);
        }
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (seatedTableId) {
            setIsModalOpen(true);
            setTableId(seatedTableId)
        }
    }, []);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsScannerOpen(true);
    };

    const handleScannerClick = () => {
        setIsScannerClicked(true);
    }

    const onFinish = (values) => {
        const userDetails = {
            name: values.name,
            telephoneNo: values.telephoneNo,
            address: null,
            tableId: tableId,
            orderType: "DINE_IN",
        };
        dispatch(addUserDetails(userDetails));
        navigation("/order");
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
            <div>
                {device?.isMobile && seatedTableId == null ? (
                    <>
                        {!isScannerClicked ? (
                            <div className="d-flex justify-content-center mb-4" onClick={handleScannerClick}>
                                <img src="../../assets/QR.png"
                                     alt="qr"/>
                            </div>
                        ) : <div className="m-3">
                            <Scanner onScan={(result) => showModal(result)}/>
                        </div>
                        }
                    </>
                ) : device?.isMobile && isScannerOpen ? (
                    <>
                      {!isScannerClicked ? (
                          <div className="d-flex justify-content-center mb-4" onClick={handleScannerClick}>
                            <img src="../../assets/QR.png"
                                 alt="qr"/>
                          </div>
                      ) : <div className="m-3">
                        <Scanner onScan={(result) => showModal(result)}/>
                      </div>
                      }
                    </>
                ) : null}
            </div>
            <Modal
                title="Customer Details"
                open={isModalOpen}
                footer={[]}
                onCancel={handleCancel}
            >
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your name!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter your name"/>
                    </Form.Item>

                    <Form.Item
                        label="Phone Number"
                        name="telephoneNo"
                        rules={[
                            {
                                required: true,
                                message: "Please input your phone number!",
                            },
                            {
                                pattern: /^[0-9\b]+$/,
                                message: "Phone number must contain only digits.",
                            },
                            {
                                len: 10,
                                message: "Phone number must be exactly 10 digits.",
                            },
                        ]}
                    >
                        <Input placeholder="Enter your phone number"/>
                    </Form.Item>

                    <Form.Item className={"d-flex text-end flex-column"}>
                        <Button onClick={handleCancel} className={"me-2"}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
