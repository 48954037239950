import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProductDetailPage from './pages/ProductDetailPage';
import Cart from './pages/Cart';
import Checkout from './pages/CheckoutPage';
import Notifications from './pages/Notifications';
import LandingPage from './pages/LandingPage';
import HomePage from './pages/HomePage';
import OrderCart from './components/PlaceOrder/OrderCart/OrderCart';
import { Provider } from 'react-redux';
import { store } from './store/Store';
import PlaceOrder from './components/PlaceOrder/PlaceOrder';
import OrderStatus from './components/PlaceOrder/OrderStatus/OrderStatus';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<HomePage />} />
        {/* <Route path="/product-detail" element={<ProductDetailPage />} /> */}
        <Route path="/order" element={<PlaceOrder />} />
        <Route path="/status" element={<OrderStatus />} />

        {/* <Route path="/checkout" element={<Checkout />} /> */}
        {/* <Route path="/notifications" element={<Notifications />} /> */}
      </Routes>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
