import React, {useState, useEffect, useRef} from "react";
import {Select, Carousel, Space, Tooltip, Button, Input, Modal} from "antd";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {
    getActivePos,
    getItemCategorie,
    getItemType,
    getSearchTag,
    getFoodItemQuantity, getPlaceholderImage,
} from "../../../services/apiBaseService";
import AddToCartModel from "../ActiveProduct/AddToCartModel/AddToCartModel";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import {selectPlaceOrderState} from "../../../store/PlaceOrderReducer";
import "./activeProduct.css";
import {Watch} from "react-loader-spinner";
import {MenuOutlined} from "@ant-design/icons";
import ImageViewerModal from "./ImageViewModel/ImageViewModel";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ActiveProduct = () => {
    const device = useDeviceDetect();
    const [activePos, setActivePos] = useState([]);
    const [categoryKeyValue, setCategoryKeyValue] = useState([]);
    const [quantityTagKeyValue, setQuantityTagKeyValue] = useState([]);
    const [typeKeyValue, setTypeKeyValue] = useState([]);
    const [searchTagKeyValue, setSearchTagKeyValue] = useState([]);
    const [menusWithFoods, setMenusWithFoods] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFood, setSelectedFood] = useState(null);
    const [currentSection, setCurrentSection] = useState("");
    const placeOrderState = useSelector(selectPlaceOrderState);
    const cartFoods = placeOrderState.cartFoods;
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const selectedMenuId = query.get("selectedMenuId");
    const sectionRefs = useRef({});
    const [isLoading, setIsLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const [isImageViewModalOpen, setIsImageViewModalOpen] = useState(false);
    const [imagesToView, setImagesToView] = useState([]);
    const [foodNameToView, setFoodNameToView] = useState("");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [placeHolderImage, setPlaceHolderImage] = useState("");

    useEffect(() => {
        getItemCategories();
        getPlaceHolderImage();
        getItemTypes();
        getSearchTags();
        getFoodItemQuantityTags();
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedMenuId && sectionRefs.current[selectedMenuId]) {
            sectionRefs.current[selectedMenuId].scrollIntoView({
                behavior: "smooth",
            });
            setCurrentSection(selectedMenuId);
        }
    }, [selectedMenuId, menusWithFoods]);

    useEffect(() => {
        const handleScroll = (event) => {
            let currentSectionId = "";
            for (const menu of menusWithFoods) {
                const sectionElement = sectionRefs.current[menu.menuId];
                if (
                    sectionElement &&
                    sectionElement.offsetTop <= event.target.scrollTop + 250 // Adjust the offset as needed
                ) {
                    currentSectionId = menu.menuId;
                }
            }
            if (currentSectionId !== currentSection) {
                setCurrentSection(currentSectionId);
            }
        };
    
        const scrollContainer = document.querySelector(".scrollItems");
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }
    
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, [currentSection, menusWithFoods]);

    const getItemCategories = async () => {
        try {
            setIsLoading(true); // Set loading state to true
            const data = await getItemCategorie();
            const categories = data.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setCategoryKeyValue(categories);
        } catch (error) {
            console.error("Error fetching categories", error);
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    const getItemTypes = async () => {
        try {
            setIsLoading(true); // Set loading state to true
            const response = await getItemType();
            const types = response.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setTypeKeyValue(types);
        } catch (error) {
            console.error("Error fetching types", error);
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    const getSearchTags = async () => {
        try {
            setIsLoading(true); // Set loading state to true
            const response = await getSearchTag();
            const tags = response.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setSearchTagKeyValue(tags);
        } catch (error) {
            console.error("Error fetching search tags", error);
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };
    useEffect(() => {
        if (categoryKeyValue.length > 0) {
            fetchData();
        }
    }, [categoryKeyValue]); // This will run every time categoryKeyValue is updated

    const getFoodItemQuantityTags = async () => {
        try {
            setIsLoading(true); // Set loading state to true
            const response = await getFoodItemQuantity();
            const quantities = response.map((item) => ({
                value: item.id,
                label: item.name,
                priceMultiply: item.pricingMultiple,
            }));
            setQuantityTagKeyValue(quantities);
        } catch (error) {
            console.error("Error fetching search tags", error);
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    const getPlaceHolderImage = async () => {
        try {
            setIsLoading(true); // Set loading state to true
            const response = await getPlaceholderImage();
            setPlaceHolderImage(response[0].customerAppImages.imageUrl);
        } catch (error) {
            console.error("Error fetching placeholder image", error);
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    }

    const fetchData = async () => {
        try {
            setIsLoading(true); // Set loading state to true
            const data = await getActivePos();
            setActivePos(data);
            groupFoodsByMenuId(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    const groupFoodsByMenuId = (data) => {
        const menuMap = [];
        data.forEach((x) => {
            let menu = menuMap.find((menu) => menu.menuId === x.menuId);

            // If the menu doesn't exist in the menuMap, create and add it
            if (!menu) {
                menu = {
                    menuId: x.menuId,
                    menuName: x.menuName,
                    menuGroupId: x.menuGroupId,
                    foods: [],
                };
                menuMap.push(menu);
            }

            const foodCategoryName = categoryKeyValue.find(
                (y) => y.value === x.foodCategoryId
            )?.label;
            // Find the category within the current menu
            let category = menu.foods.find(
                (c) => c.categoryName === foodCategoryName
            );

            // If the category doesn't exist, create it
            if (!category) {
                category = {
                    categoryName: foodCategoryName,
                    categoryFoods: [],
                };
                menu.foods.push(category);
            }

            // Add the food to the category's food array
            category.categoryFoods.push({
                ...x,
                imageUrls: x.foodImageUrl == null ? null : x.foodImageUrl.split(","),
            });
        });

        // Optionally, sort the categories and their foods if necessary
        menuMap.forEach((menu) => {
            // Sorting categories by name (optional)
            menu.foods.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

            // Sorting the foods within each category (optional)
            menu.foods.forEach((category) => {
                category.categoryFoods.sort((a, b) =>
                    a.foodName.localeCompare(b.foodName)
                );
            });
        });
        const menusArray = Array.from(menuMap.values());

        console.log("menusArray", menusArray);
        setMenusWithFoods(menusArray);
    };

    const onAddToCart = (food) => {
        setSelectedFood(food);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedFood(null);
    };

    const handleImageClick = (images, foodName, index) => {
        setImagesToView(images);
        setFoodNameToView(foodName);
        setCurrentImageIndex(index);
        setIsImageViewModalOpen(true);
    };

    const closeImageViewModal = () => {
        setIsImageViewModalOpen(false);
    };

    const scrollTo = (sectionId) => {
        sectionRefs.current[sectionId].scrollIntoView({ behavior: "smooth" });
        requestAnimationFrame(() => {
            setCurrentSection(sectionId);
        });
    };

    const filterFoods = (selectedCategories, selectedTypes, selectedTags) => {
        let filtered = activePos;

        if (selectedCategories.length > 0) {
            filtered = filtered.filter((food) =>
                selectedCategories.includes(food.foodCategoryId)
            );
        }

        if (selectedTypes.length > 0) {
            filtered = filtered.filter(
                (food) =>
                    Array.isArray(food.foodTypeIds) &&
                    food.foodTypeIds.some((typeId) => selectedTypes.includes(typeId))
            );
        }

        if (selectedTags.length > 0) {
            filtered = filtered.filter(
                (food) =>
                    Array.isArray(food.foodSearchTagIds) &&
                    food.foodSearchTagIds.some((tagId) => selectedTags.includes(tagId))
            );
        }

        groupFoodsByMenuId(filtered);
    };

    const handleCategoryChange = (selectedCategories) => {
        setSelectedCategories(selectedCategories);
        filterFoods(selectedCategories, selectedTypes, selectedTags);
    };

    const handleTypeChange = (selectedTypes) => {
        setSelectedTypes(selectedTypes);
        filterFoods(selectedCategories, selectedTypes, selectedTags);
    };

    const handleSearchTagChange = (selectedTags) => {
        setSelectedTags(selectedTags);
        filterFoods(selectedCategories, selectedTypes, selectedTags);
    };

    const truncateText = (text, limit) => {
        return text?.length > limit ? text.substring(0, limit) + "..." : text;
    };

    const getFoodQuantityInCart = (foodId) => {
        const foodItems = cartFoods.filter((food) => food.foodId === foodId);
        return foodItems.length > 0
            ? foodItems.reduce((sum, foodItem) => sum + foodItem.quantity, 0)
            : 0;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const liveSearch = (value) => {
        setSearchValue(value);
        const filtered = activePos.filter((food) => {
            const category = categoryKeyValue
                .find((cat) => cat.value === food.foodCategoryId)
                ?.label.toLowerCase();
            const tags = Array.isArray(food.foodSearchTagIds)
                ? food.foodSearchTagIds
                    .map((tagId) =>
                        searchTagKeyValue
                            .find((tag) => tag.value === tagId)
                            ?.label.toLowerCase()
                    )
                    .join(" ")
                : "";
            const types = Array.isArray(food.foodTypeIds)
                ? food.foodTypeIds
                    .map((typeId) =>
                        typeKeyValue
                            .find((type) => type.value === typeId)
                            ?.label.toLowerCase()
                    )
                    .join(" ")
                : "";

            const foodName = food.foodName?.toLowerCase();
            const foodItemCode = food.foodItemCode?.toLowerCase();

            return (
                foodName?.includes(value.toLowerCase()) ||
                foodItemCode?.includes(value.toLowerCase()) ||
                (category && category.includes(value.toLowerCase())) ||
                (tags && tags.includes(value.toLowerCase())) ||
                (types && types.includes(value.toLowerCase()))
            );
        });
        groupFoodsByMenuId(filtered);
    };

    return (
        <div>
            {isLoading ? (
                <div className="loader-overlay">
                    <Watch
                        open={true}
                        height="80"
                        width="80"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                    />
                </div>
            ) : device?.isMobile ? (
                <div>
                    <ImageViewerModal
                        open={isImageViewModalOpen}
                        onClose={closeImageViewModal}
                        images={imagesToView}
                        foodName={foodNameToView}
                        initialIndex={currentImageIndex}
                    />
                    <AddToCartModel
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        foodItem={selectedFood}
                        quantityTagKeyValue={quantityTagKeyValue}
                    />
                    <Modal
                        title={<div style={{fontSize: '20px', fontWeight: 'bold'}}>Filter Items</div>}
                        open={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText="Apply"
                        cancelText="Cancel"

                        okButtonProps={{
                            style: {
                                width: '45%',
                                height: '40px',
                                padding: '16px', // Increase padding for better touchability
                                fontSize: '18px', // Increase font size for better readability

                            }
                        }}
                        cancelButtonProps={{
                            style: {
                                width: '45%',
                                height: '40px',
                                padding: '16px', // Increase padding for better touchability
                                fontSize: '18px', // Increase font size for better readability
                            }
                        }}
                    >
                        <Space
                            direction="vertical"
                            className="w-100 me-3 mt-2 mb-4"
                            style={{gap: "20px"}} // Increased gap between filters
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch={false}
                                style={{
                                    width: "100%",
                                    height: '40px',
                                    fontSize: "18px", // Larger font size for readability
                                }}
                                placeholder="Food Item Category"
                                onChange={handleCategoryChange}
                                options={categoryKeyValue}
                            />
                        </Space>

                        <Space
                            direction="vertical"
                            className="w-100 me-3 mb-4"
                            style={{gap: "20px"}}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch={false}
                                style={{
                                    width: "100%",
                                    height: '40px',
                                    fontSize: "18px",
                                }}
                                placeholder="Food Item Type"
                                onChange={handleTypeChange}
                                options={typeKeyValue}
                            />
                        </Space>

                        <Space
                            direction="vertical"
                            className="w-100 me-3 mb-4"
                            style={{gap: "20px"}}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch={false}
                                style={{
                                    width: "100%",
                                    height: '40px',
                                    fontSize: "18px",
                                }}
                                placeholder="Search Tag"
                                onChange={handleSearchTagChange}
                                options={searchTagKeyValue}
                            />
                        </Space>
                    </Modal>

                    <div>
                        <div className="d-flex ms-3 me-3 mb-2">
                            <Input
                                type="text"
                                placeholder="Live Search"
                                value={searchValue}
                                onChange={(e) => liveSearch(e.target.value)}
                                style={{width: "100%", padding: "5px"}}
                            />
                            <Button
                                className="ms-2 ps-3 pe-3"
                                type="primary"
                                onClick={showModal}
                                icon={<MenuOutlined/>}
                                style={{backgroundColor: "black", borderColor: "black"}}
                            />{" "}
                        </div>

                        <div
                            className="ps-2 pe-2 bg-white header01"
                            id="header01"
                            style={{zIndex: 1000}}
                        >
                            <div className="d-flex overflow-auto pt-0" ref={sectionRefs}>
                                {menusWithFoods?.map((menu) => (
                                    <div
                                        key={menu?.menuId}
                                        onClick={() => scrollTo(menu?.menuId)}
                                        style={{
                                            border: "1px solid #ccc",
                                            padding: "10px",
                                            margin: "5px",
                                            width: "100%",
                                            borderRadius: "5px",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <p className="mb-0 text-center text-weight">
                                            {menu.menuName}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="scrollItemsMobile">
                            {menusWithFoods?.map((menu) => (
                                <section
                                    key={menu?.menuId}
                                    id={menu?.menuId}
                                    ref={(el) => {
                                        if (el) {
                                            sectionRefs.current[menu?.menuId] = el;
                                        }
                                    }}
                                >
                                    <div className="row ms-1 me-2" style={{zIndex: -100}}>
                                        <div className="text-muted header">{menu.menuName}</div>

                                        {/* Iterate over each food category */}
                                        {menu?.foods?.map((category) => (
                                            <div key={category?.categoryName}>
                                                <h5 className="category-header">
                                                    {category.categoryName}
                                                </h5>

                                                {/* Iterate over foods within this category */}
                                                <div className="row">
                                                    {category?.categoryFoods?.map((food) => (
                                                        <div className="col-6 mb-3 mt-2" key={food?.foodId}>
                                                            <div className="food-item-box-mobile pb-3">
                                                                <div className="image-container">
                                                                    {food.imageUrls && food.imageUrls.length > 0 ? (
                                                                        food.imageUrls.length === 1 ? (
                                                                            <img
                                                                                src={food.imageUrls[0]}
                                                                                alt={food?.foodName}
                                                                                className="card-image"
                                                                                width="100%"
                                                                            />
                                                                        ) : (
                                                                            <Carousel autoplay>
                                                                                {food.imageUrls.map((url, index) => (
                                                                                    <div key={index}
                                                                                         style={{height: "120px"}}>
                                                                                        <img
                                                                                            src={url}
                                                                                            alt={food?.foodName}
                                                                                            className="card-image"
                                                                                            width="100%"
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Carousel>
                                                                        )
                                                                    ) : (
                                                                        <img
                                                                            src={placeHolderImage} // Use a placeholder image if imageUrls is null or empty
                                                                            alt={food?.foodName}
                                                                            className="card-image"
                                                                            width="100%"
                                                                        />
                                                                    )}
                                                                    <div className="price-label">
                                                                        Rs.{food.price}
                                                                    </div>
                                                                    <div
                                                                        className="image-label"
                                                                        onClick={() => {
                                                                            if (food.imageUrls !== null) {
                                                                                handleImageClick(
                                                                                    food.imageUrls,
                                                                                    food.foodName
                                                                                )
                                                                            }
                                                                        }
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faEye}/>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="text-weight ms-2 me-2 pt-1"
                                                                    style={{height: "100%"}}
                                                                >
                                                                    {food.foodName}
                                                                </div>
                                                                <div
                                                                    className="text-muted ms-2 me-2"
                                                                    style={{height: "100%"}}
                                                                >
                                                                    <Tooltip title={food.foodDescription}>
                                                                        {truncateText(food.foodDescription, 20)}
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="ps-2 pe-2">
                                                                    <Button
                                                                        type="primary"
                                                                        style={{
                                                                            width: "100%",
                                                                            backgroundColor: getFoodQuantityInCart(
                                                                                food.foodId
                                                                            )
                                                                                ? "red"
                                                                                : "#006D5B",
                                                                            borderColor: getFoodQuantityInCart(
                                                                                food.foodId
                                                                            )
                                                                                ? "red"
                                                                                : "#006D5B",
                                                                        }}
                                                                        onClick={() => onAddToCart(food)}
                                                                    >
                                                                        Add{" "}
                                                                        {getFoodQuantityInCart(food.foodId) > 0 &&
                                                                            `(${getFoodQuantityInCart(food.foodId)})`}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div>
                        <ImageViewerModal
                            open={isImageViewModalOpen}
                            onClose={closeImageViewModal}
                            images={imagesToView}
                            foodName={foodNameToView}
                            initialIndex={currentImageIndex}
                        />
                        <AddToCartModel
                            open={isModalOpen}
                            onClose={handleCloseModal}
                            foodItem={selectedFood}
                            quantityTagKeyValue={quantityTagKeyValue}
                        />
                        <div>
                            <div
                                className="row-container ms-2 me-2 bg-white header01"
                                id="header01"
                                style={{zIndex: 1000}}
                            >
                                <div className="d-flex pt-0" ref={sectionRefs}>
                                    {menusWithFoods?.map((menu) => (
                                        <div
                                            key={menu?.menuId}
                                            className={`menu-box ${
                                                currentSection === menu?.menuId ? "menu-selected" : ""
                                            }`}
                                            onClick={() => scrollTo(menu?.menuId)}
                                        >
                                            <p className="mb-0 text-center text-weight">
                                                {menu.menuName}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <div className="ms-3">
                                    <Input
                                        type="text"
                                        placeholder="Live Search"
                                        value={searchValue}
                                        onChange={(e) => liveSearch(e.target.value)}
                                        style={{width: 250, padding: "5px"}}
                                    />{" "}
                                </div>
                                <div>
                                    <Space direction="vertical" className="me-3">
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{width: 200}}
                                            placeholder="Food Item Category"
                                            onChange={handleCategoryChange}
                                            options={categoryKeyValue}
                                        />
                                    </Space>
                                    <Space direction="vertical" className="me-3">
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{width: 200}}
                                            placeholder="Food Item Type"
                                            onChange={handleTypeChange}
                                            options={typeKeyValue}
                                        />
                                    </Space>
                                    <Space direction="vertical" className="me-3">
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{width: 200}}
                                            placeholder="Search Tag"
                                            onChange={handleSearchTagChange}
                                            options={searchTagKeyValue}
                                        />
                                    </Space>
                                </div>
                            </div>
                            <div className="scrollItems">
                                {menusWithFoods?.map((menu) => (
                                    <section
                                        key={menu?.menuId}
                                        id={menu?.menuId}
                                        ref={(el) => {
                                            if (el) {
                                                sectionRefs.current[menu?.menuId] = el;
                                            }
                                        }}
                                    >
                                        <div className="row ms-1 me-2" style={{zIndex: -100}}>
                                            <div
                                                className="text-muted header"
                                                style={{zIndex: -100}}
                                            >
                                                {menu?.menuName}
                                            </div>

                                            {/* Iterate over each food category */}
                                            {menu?.foods?.map((category) => (
                                                <div key={category?.categoryName}>
                                                    <h5 className="category-header">
                                                        {category.categoryName}
                                                    </h5>

                                                    {/* Iterate over foods within this category */}
                                                    <div className="row">
                                                        {category?.categoryFoods?.map((food) => (
                                                            <div
                                                                key={food?.foodId}
                                                                className="col-md-4 col-lg-3 col-xl-2 mx-lg-2 me-3 mb-3 food-item-box"
                                                            >
                                                                <div className="food-card">
                                                                    <div className="image-container">
                                                                        {food.imageUrls && food.imageUrls.length > 0 ? (
                                                                            food.imageUrls.length === 1 ? (
                                                                                <img
                                                                                    src={food.imageUrls[0]}
                                                                                    alt={food?.foodName}
                                                                                    className="card-image"
                                                                                    width="100%"
                                                                                />
                                                                            ) : (
                                                                                <Carousel autoplay>
                                                                                    {food.imageUrls.map((url, index) => (
                                                                                        <div key={index}
                                                                                             style={{height: "120px"}}>
                                                                                            <img
                                                                                                src={url}
                                                                                                alt={food?.foodName}
                                                                                                className="card-image"
                                                                                                width="100%"
                                                                                            />
                                                                                        </div>
                                                                                    ))}
                                                                                </Carousel>
                                                                            )
                                                                        ) : (
                                                                            <img
                                                                                src={placeHolderImage} // Use a placeholder image if imageUrls is null or empty
                                                                                alt={food?.foodName}
                                                                                className="card-image"
                                                                                width="100%"
                                                                            />
                                                                        )}


                                                                        <div className="price-label">
                                                                            Rs.{food.price}
                                                                        </div>
                                                                        <div
                                                                            className="image-label"
                                                                            onClick={() => {
                                                                                if (food.imageUrls !== null) {
                                                                                    handleImageClick(
                                                                                        food.imageUrls,
                                                                                        food.foodName
                                                                                    )
                                                                                }
                                                                            }
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faEye}/>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="text-weight ms-2 me-2 pt-1"
                                                                        style={{height: "100%"}}
                                                                    >
                                                                        {food.foodName}
                                                                    </div>
                                                                    <div
                                                                        className="text-muted ms-2 me-2"
                                                                        style={{height: "50px"}}
                                                                    >
                                                                        <Tooltip title={food.foodDescription}>
                                                                            {truncateText(food.foodDescription, 30)}
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div className="mt-2 ps-2 pe-2">
                                                                        <Button
                                                                            type="primary"
                                                                            className="cursor"
                                                                            style={{
                                                                                width: "100%",
                                                                                backgroundColor: getFoodQuantityInCart(
                                                                                    food.foodId
                                                                                )
                                                                                    ? "red"
                                                                                    : "#006D5B",
                                                                                borderColor: getFoodQuantityInCart(
                                                                                    food.foodId
                                                                                )
                                                                                    ? "red"
                                                                                    : "#006D5B",
                                                                            }}
                                                                            onClick={() => onAddToCart(food)}
                                                                        >
                                                                            Add{" "}
                                                                            {getFoodQuantityInCart(food.foodId) > 0 &&
                                                                                `(${getFoodQuantityInCart(
                                                                                    food.foodId
                                                                                )})`}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </section>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ActiveProduct;
