import React from "react";
import useDeviceDetect from "../../../hooks/useDeviceDetect";

export default function NavBar() {
  const device = useDeviceDetect();

  return (
    <div>
      {device?.isMobile ? (
        <>
          <div className="  align-items-center pt-1 pb-1 ps-3 pe-3">
            <div className="d-flex justify-content-between align-items-center">
              <img
                src="../../assets/CompanyLogo.png"
                alt="avatar"
                style={{
                  height: "50px",
                  cursor: "pointer",
                }}
              />
              {/* <div
                className="ms-3"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Sign In
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="  align-items-center pt-1 pb-1 ps-5 pe-5">
            <div className="d-flex justify-content-between align-items-center">
              <img
                src="../../assets/CompanyLogo.png"
                alt="avatar"
                style={{
                  cursor: "pointer",
                  height: "80px",
                }}
              />
              {/* <div
                className="ms-3"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Sign In
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
