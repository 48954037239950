import React from 'react';
import './SearchBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'antd';
import useDeviceDetect from '../../hooks/useDeviceDetect';

export default function SearchBar() {

    const device = useDeviceDetect();

    const content = (
        <div>
            <div className="card" style={{
                border: 'none',
            }}>
                <div className="card-header" style={{
                    backgroundColor: '#0066A8',
                    color: '#FFFFFF',
                    fontSize: '16px',
                    fontWeight: '600',
                }}>
                    Notifications
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-2 ps-0'>
                            <img src='https://www.w3schools.com/howto/img_avatar.png' alt='avatar' style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                            }} />
                        </div>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='ps-0' style={{
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#005B9C',
                                }}>
                                    Mago das Milhas
                                </div>
                            </div>
                            <div className='row'>Lorem ipsum dolor sit amet, consectetur.</div>
                        </div>
                    </div>
                    <div className='row py-3'>
                        <div className='col-2 ps-0'>
                            <img src='https://www.w3schools.com/howto/img_avatar.png' alt='avatar' style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                            }} />
                        </div>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='ps-0' style={{
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#005B9C',
                                }}>
                                    Mago das Milhas
                                </div>
                            </div>
                            <div className='row'>Lorem ipsum dolor sit amet, consectetur.</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-2 ps-0'>
                            <img src='https://www.w3schools.com/howto/img_avatar.png' alt='avatar' style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                            }} />
                        </div>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='ps-0' style={{
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#005B9C',
                                }}>
                                    Mago das Milhas
                                </div>
                            </div>
                            <div className='row'>Lorem ipsum dolor sit amet, consectetur.</div>
                        </div>
                    </div>
                    <div className='row pt-3'>
                        <div className='col-2 ps-0'>
                            <img src='https://www.w3schools.com/howto/img_avatar.png' alt='avatar' style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                            }} />
                        </div>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='ps-0' style={{
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#005B9C',
                                }}>
                                    Mago das Milhas
                                </div>
                            </div>
                            <div className='row'>Lorem ipsum dolor sit amet, consectetur.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            {
                device?.isMobile ? (
                    <div>
                        <div className='row px-4' style={{
                            '--bs-gutter-x': '0',
                        }}>
                            <div className='pt-3'>
                                <div className="form-group has-search" style={{ paddingRight: 0 }}>
                                    <div className='d-flex justify-content-end'>
                                        <FontAwesomeIcon style={{
                                            width: '18px',
                                            height: '18px',
                                            padding: '10px'
                                        }} icon={faSearch} className="form-control-feedback pe-3" />
                                    </div>

                                    <input type="text" className="form-control" placeholder="Search" style={{
                                        borderRadius: '20px',
                                        paddingLeft: '20px',
                                        backgroundColor: '#F1F6F7',
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='row'>
                            <div className='col-8'>
                                <div className='pt-3'>
                                    <div className="form-group has-search">
                                        <div className='d-flex justify-content-end'>
                                            <FontAwesomeIcon style={{
                                                width: '18px',
                                                height: '18px',
                                                padding: '10px'
                                            }} icon={faSearch} className="form-control-feedback pe-3" />
                                        </div>

                                        <input type="text" className="form-control" placeholder="Search" style={{
                                            borderRadius: '30px',
                                            backgroundColor: '#F1F6F7',
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='d-flex justify-content-end pt-3 pe-1'>
                                    <Popover content={content} trigger="click">
                                        <button className='' style={{
                                            backgroundColor: '#FFFFFF',
                                            border: 'none',
                                        }}>
                                            <span className="bi bi-bell-fill" style={{
                                                fontSize: '25px',
                                            }}></span>
                                        </button>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

        </div>
    );
}

