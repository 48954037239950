import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  deleteFoodFromCart,
} from "../../../store/PlaceOrderActions";
import { Button, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import "./OrderCart.css";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import { selectPlaceOrderState } from "../../../store/PlaceOrderReducer";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createDeliveryOrder,
  createDineInOrder,
  createTakeAwayOrder,
  getFoodItemQuantity,
} from "../../../services/apiBaseService";
import { Watch } from "react-loader-spinner";

const OrderCart = () => {
  const device = useDeviceDetect();
  const dispatch = useDispatch();
  const placeOrderState = useSelector(selectPlaceOrderState);
  const [quantityTagValue, setQuantityTagValue] = useState([]);
  const cartFoods = placeOrderState.cartFoods;
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getFoodItemQuantityTags();
  }, []);

  const handleDeleteFood = (foodItem) => {
    dispatch(deleteFoodFromCart(foodItem));
  };

  const handleCreateOrder = () => {
    setIsLoading(true); // Set loading state to true

    const cartRequestDetails = cartFoods.filter(
      (food) => food.id === undefined
    );

    const data = {
      tableId: placeOrderState.userDetails?.tableId,
      telephoneNo: placeOrderState.userDetails?.telephoneNo,
      name: placeOrderState.userDetails?.name,
      address: placeOrderState.userDetails?.address,
      customerOrderDetails: cartRequestDetails,
    };

    const handleOrder = (orderFunc) => {
      orderFunc(data)
        .then((r) => {
          dispatch(clearCart());
          alert("Order created successfully!");
          navigation("/");
        })
        .finally(() => {
          setIsLoading(false); // Stop loader
        });
    };

    switch (placeOrderState.userDetails?.orderType) {
      case "DELIVERY":
        handleOrder(createDeliveryOrder);
        break;
      case "TAKEAWAY":
        handleOrder(createTakeAwayOrder);
        break;
      case "DINE_IN":
        handleOrder(createDineInOrder);
        break;
      default:
        setIsLoading(false); // Stop loader if no valid order type
        alert("Please select a valid order type");
    }
  };

  const handleCloseOrder = () => {
    dispatch(clearCart());
    navigation("/");
  };

  const getSubtotal = () => {
    return cartFoods?.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
  };

  const formatPrice = (price) => {
    return price.toLocaleString("en-IN", { minimumFractionDigits: 0 });
  };

  const truncateText = (text, limit) => {
    return text?.length > limit ? text.substring(0, limit) + "..." : text;
  };

  const getFoodItemQuantityTags = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const response = await getFoodItemQuantity();
      setQuantityTagValue(response);
    } catch (error) {
      console.error("Error fetching search tags", error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const getQuantityTagName = (id, quantityTags) => {
    const tag = quantityTags.find((tag) => tag.id === id);
    return tag ? tag.name : "";
  };

  return (
    <div>
      {isLoading ? (
        <div className="loader-overlay">
          <Watch
            visible={true}
            height="80"
            width="80"
            radius="48"
            color="#4fa94d"
            ariaLabel="watch-loading"
          />
        </div>
      ):device?.isMobile ? (
        <>
          <div>
            <div>
              {cartFoods?.map((foodItem, i) => (
                <div className="row" key={i}>
                  <div className="col-1">
                    <a onClick={() => handleDeleteFood(foodItem)}>
                      <FontAwesomeIcon className="text-danger" icon={faXmark} />
                    </a>
                  </div>
                  <div className="col-2">
                    <div>{foodItem?.quantity}</div>
                    <small>
                      <i>
                        {getQuantityTagName(
                          foodItem.foodQuantityTag,
                          quantityTagValue
                        )}
                      </i>
                    </small>
                  </div>
                  <div className="col-6">
                    <div className="h7 text-weight">{foodItem?.name}</div>
                    <Tooltip title={foodItem?.kitchenIntroduction}>
                      {truncateText(foodItem?.kitchenIntroduction, 20)}
                    </Tooltip>
                  </div>
                  <div className="col-3 text-end">
                    <div>
                      Rs.{formatPrice(foodItem.price * foodItem.quantity)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <hr />
            <div className="row">
              <div className="col-9 h7">Total</div>
              <div className="col-3 h7 text-end">
                Rs.{formatPrice(getSubtotal())}
              </div>
            </div>
            <hr />
            <div className="mb-2">
              <Button
                type="primary"
                className="w-100"
                onClick={handleCreateOrder}
              >
                Create Order
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="box mt-5 p-3 shadow">
            <div className="scrollItems1">
              {cartFoods?.map((foodItem, i) => (
                <div className="row" key={i}>
                  <div className="col-1">
                    <a onClick={() => handleDeleteFood(foodItem)}>
                      <FontAwesomeIcon className="text-danger" icon={faXmark} />
                    </a>
                  </div>
                  <div className="col-2">
                    <div>{foodItem?.quantity}</div>
                    <small>
                      <i>
                        {getQuantityTagName(
                          foodItem.foodQuantityTagId,
                          quantityTagValue
                        )}
                      </i>
                    </small>
                  </div>
                  <div className="col-6">
                    <div className="h7 text-weight">{foodItem?.name}</div>
                    <Tooltip title={foodItem?.kitchenIntroduction}>
                      {truncateText(foodItem?.kitchenIntroduction, 20)}
                    </Tooltip>
                  </div>
                  <div className="col-3 text-end">
                    <div>
                      Rs.{formatPrice(foodItem.price * foodItem.quantity)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <hr />
            <div className="row">
              <div className="col-9 h7">Total</div>
              <div className="col-3 h7 text-end pe-5">
                Rs.{formatPrice(getSubtotal())}
              </div>
            </div>
            <hr />
            <div className="mb-2">
              <Button
                type="primary"
                className="w-100"
                onClick={handleCreateOrder}
              >
                Create Order
              </Button>
            </div>
            {/*<div className="mb-2">*/}
            {/*  <Button*/}
            {/*    type="primary"*/}
            {/*    danger*/}
            {/*    className="w-100"*/}
            {/*    onClick={handleCloseOrder}*/}
            {/*  >*/}
            {/*    Close Order*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
        </>
      )}
    </div>
  );
};

export default OrderCart;
