import { useEffect } from "react";

const useBeforeUnload = (handler) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const returnValue = handler();
      if (returnValue) {
        event.preventDefault();
        event.returnValue = returnValue;
      }
      return returnValue;
    };

    const handlePopState = (event) => {
      const returnValue = handler();
      if (returnValue && !window.confirm(returnValue)) {
        window.history.pushState(null, "", window.location.href);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [handler]);
};

export default useBeforeUnload;
