import React, { useState, useEffect } from "react";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import { getPopularDeals } from "../../../services/apiBaseService";
import { Watch } from "react-loader-spinner";

export default function PopularDeals() {
  const device = useDeviceDetect();
  const [popularDeals, setPopularDeals] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPopularDeal();
  }, []);

  const getPopularDeal = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const response = await getPopularDeals();
      setPopularDeals(response);
    } catch (error) {
      console.error("Error fetching popular deals", error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      {isLoading ? (
        <div className="loader-overlay">
          <Watch
            visible={true}
            height="80"
            width="80"
            radius="48"
            color="#4fa94d"
            ariaLabel="watch-loading"
          />
        </div>
      ) : device?.isMobile ? (
        <>
          <div className="d-flex align-items-center justify-content-center pt-3 pb-2">
            <div
              style={{
                flexGrow: 1,
                height: "1px",
                backgroundColor: "black",
              }}
            ></div>
            <div
              className="px-3"
              style={{
                fontSize: "28px",
                fontWeight: "300",
              }}
            >
              Our Most Popular Deals
            </div>
            <div
              style={{
                flexGrow: 1,
                height: "1px",
                backgroundColor: "black",
              }}
            ></div>
          </div>
          <div className="ps-5 pe-5 pb-5">
            <div>
              <div className="row">
                {(showAll ? popularDeals : popularDeals.slice(0, 4)).map(
                  (deal) => (
                    <div key={deal.id} className="col-12 p-3">
                      <img
                        src={deal.customerAppImages.imageUrl}
                        alt="popular deal"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            <div>
              <button
                className={`btn w-100 ${
                  showAll ? "btn-secondary" : "btn-success"
                }`}
                onClick={toggleShowAll}
              >
                {showAll ? "View Less Deals" : "View All Deals"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-center pt-5 pb-2">
            <div
              style={{
                flexGrow: 1,
                height: "1px",
                backgroundColor: "black",
              }}
            ></div>
            <div
              className="px-3"
              style={{
                fontSize: "28px",
                fontWeight: "300",
              }}
            >
              Our Most Popular Deals
            </div>
            <div
              style={{
                flexGrow: 1,
                height: "1px",
                backgroundColor: "black",
              }}
            ></div>
          </div>
          <div className="ps-5 pe-5 pb-5">
            <div>
              <div className="row">
                {(showAll ? popularDeals : popularDeals.slice(0, 4)).map(
                  (deal) => (
                    <div key={deal.id} className="col-6 p-3">
                      <img
                        src={deal.customerAppImages.imageUrl}
                        alt="popular deal"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            <div>
              <button
                className={`btn w-100 ${
                  showAll ? "btn-secondary" : "btn-success"
                }`}
                onClick={toggleShowAll}
              >
                {showAll ? "View Less Deals" : "View All Deals"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
