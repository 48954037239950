import React, {useEffect, useState} from "react";

const useSubject = (subject) => {

    const [state, setState] = useState();

    useEffect(() => {
        const sub = subject.subscribe(setState);
        return () => sub.unsubscribe();
    }, [subject]);

    return [state];
};

export default useSubject;
