import { useState, useEffect } from 'react';

function useDeviceDetect() {
  const [device, setDevice] = useState(null);


    function handleWindowSizeChange() {
        const userAgent = navigator.userAgent;
        const isMobile = Boolean(
            userAgent.match(
                /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
            )
        );

        const isTablet = Boolean(
            userAgent.match(/iPad/i) ||
            (userAgent.match(/Android/i) && !userAgent.match(/Mobile/i))
        );
        const isLargeDesktop = window.innerWidth > 1600;
        const isDesktop = window.innerWidth > 1000 && window.innerWidth < 1600;
        setDevice({ isMobile, isDesktop, isTablet, isLargeDesktop });
    }

  useEffect(() => {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  return device;
}

export default useDeviceDetect;