import { createReducer } from "@reduxjs/toolkit";
import {
  addFoodToCart,
  deleteFoodFromCart,
  addPrintedFoodsToCart,
  clearCart,
  addUserDetails,
} from "./PlaceOrderActions";

const initialState = {
  userDetails: {},
  cartFood: {},
  cartFoods: [],
  isPrinted: false,
};

export const placeOrderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addUserDetails, (state, action) => {
      state.userDetails = action.payload;
    })
    .addCase(addFoodToCart, (state, action) => {
      const foodCart = [...state.cartFoods];
      const payload = action.payload;

      const foodExists = foodCart.some(
        (food) =>
          food.foodId === payload.foodId &&
          food.foodQuantityTagId === payload.foodQuantityTagId &&
          food.kitchenIntroduction === payload.kitchenIntroduction &&
          food.id === undefined &&
          ((Array.isArray(food.extras) &&
            Array.isArray(payload.extras) &&
            food.extras.length === payload.extras.length &&
            food.extras.every(
              (extra, index) => extra === payload.extras[index]
            )) ||
            (food.extras === null && payload.extras === null))
      );

      if (foodExists) {
        foodCart.forEach((food, index) => {
          if (
            food.foodId === payload.foodId &&
            food.foodQuantityTagId === payload.foodQuantityTagId &&
            food.kitchenIntroduction === payload.kitchenIntroduction &&
            ((Array.isArray(food.extras) &&
              Array.isArray(payload.extras) &&
              food.extras.length === payload.extras.length &&
              food.extras.every(
                (extra, index) => extra === payload.extras[index]
              )) ||
              (food.extras === null && payload.extras === null))
          ) {
            foodCart[index] = {
              ...food,
              quantity: food.quantity + payload.quantity,
            };
          }
        });
      } else {
        foodCart.push(payload);
      }
      state.cartFood = payload;
      state.cartFoods = foodCart;
    })
    .addCase(deleteFoodFromCart, (state, action) => {
      const payload = action.payload;
      state.cartFoods = state.cartFoods.filter((food) => {
        const isSameFood = food.foodId === payload.foodId;
         const isSameFoodQuantity = food.foodQuantityTagId === payload.foodQuantityTagId;
        const isSameKitchenInstruction =
          food.kitchenIntroduction === payload.kitchenIntroduction;
        const isSameExtras =
          food.extras &&
          payload.extras &&
          food.extras.length === payload.extras.length &&
          food.extras.every((extra, index) => extra === payload.extras[index]);

        return !(isSameFood && isSameKitchenInstruction && isSameExtras&&isSameFoodQuantity);
      });
    })

    .addCase(addPrintedFoodsToCart, (state, action) => {
      state.cartFoods = action.payload;
      state.isPrinted = true;
    })
    .addCase(clearCart, (state) => {
      state.cartFoods = [];
    });
});

export const selectPlaceOrderState = (state) => state.placeOrder;

export default placeOrderReducer;
