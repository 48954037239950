import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectPlaceOrderState } from "../../store/PlaceOrderReducer";
import ActiveProduct from "./ActiveProduct/ActiveProduct";
import OrderCart from "./OrderCart/OrderCart";
import { faSearch, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../../store/PlaceOrderActions";
import useBeforeUnload from "../../hooks/useBeforeUnload";

const PlaceOrder = () => {
  const device = useDeviceDetect();
  const [toggle, setToggle] = useState(false);
  const [foodCount, setFoodCount] = useState(0);
  const placeOrderState = useSelector(selectPlaceOrderState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setFoodCount(placeOrderState.cartFoods.length);
  }, [placeOrderState]);

  const canDeactivate = () => {
    if (placeOrderState.cartFoods.length > 0) {
      return "You have unsaved changes. Do you really want to leave?";
    }
    return null;
  };

  useBeforeUnload(canDeactivate);

  const backToHome = () => {
    if (!canDeactivate() || window.confirm(canDeactivate())) {
      dispatch(clearCart());
      navigate("/");
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    const onPopState = (event) => {
      const returnValue = canDeactivate();
      if (returnValue && !window.confirm(returnValue)) {
        window.history.pushState(null, "", window.location.href);
      }
    };
    window.addEventListener("popstate", onPopState);

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, [canDeactivate]);

  return (
    <div>
      {device?.isMobile ? (
        <>
          <div className="d-flex mt-2 mb-3 justify-content-between align-items-center">
            <img
              src="../../assets/CompanyLogo.png"
              alt="avatar"
              onClick={backToHome}
              style={{ height: "50px" }}
            />
            <div
              onClick={() => setToggle(!toggle)}
              className="click-behaviour d-flex cursor mb-2"
              title={toggle ? "Hide" : "Show"}
            >
              <p className="text-weight me-3 fs-5 my-auto">
                {foodCount}
                <FontAwesomeIcon
                  icon={faCartShopping}
                  className="ms-2"
                  style={{ fontSize: "25px", color: "#000000" }}
                />
              </p>
            </div>
          </div>
          <div className="mt-2 mb-2">
            <ActiveProduct />
          </div>
          <Modal
            open={toggle}
            onCancel={() => setToggle(false)}
            footer={null}
            title="Your Cart"
            centered
          >
            <OrderCart />
          </Modal>
        </>
      ) : (
        <>
          <div className="align-items-center pt-1 pb-1 ps-5 pe-5">
            <div className="d-flex justify-content-between align-items-center">
              <img
                src="../../assets/CompanyLogo.png"
                alt="avatar"
                onClick={backToHome}
                style={{
                  height: "80px",
                }}
              />
              {/* <div className="col-8">
                <div className="form-group has-search">
                  <div className="d-flex justify-content-end">
                    <FontAwesomeIcon
                      style={{
                        width: "18px",
                        height: "18px",
                        padding: "10px",
                      }}
                      icon={faSearch}
                      className="form-control-feedback pe-3"
                    />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    style={{
                      borderRadius: "30px",
                      backgroundColor: "#F1F6F7",
                    }}
                  />
                </div>
              </div> */}
              <div
                onClick={() => setToggle(!toggle)}
                className="click-behaviour d-flex cursor mb-2"
                title={toggle ? "Hide" : "Show"}
              >
                <p className="text-weight me-3 fs-5 my-auto">
                  {foodCount}
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    className="ms-2"
                    style={{ fontSize: "25px", color: "#000000" }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className={toggle ? "col-8" : "col-12"}>
              <ActiveProduct />
            </div>
            {toggle && (
              <div className="col-4">
                <OrderCart />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PlaceOrder;
