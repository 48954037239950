import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import './ImageViewModel.css'; 
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ImageViewerModal = ({ open, onClose, images, foodName }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const currentImage = images[currentImageIndex];

  const onPrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const onNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Modal
      title={foodName}
      open={open}
      onCancel={onClose}
      footer={null}
      width={700}
    >
      <div className="image-container position-relative">
        {images.length > 1 && (
          <Button
            className="arrow-button left-arr"
            onClick={onPrev}
          >
            <FontAwesomeIcon
              icon={faBackward}
              className="ms-2"
              style={{ fontSize: "25px", color: "#000000" }}
            />
          </Button>
        )}
        <img
          src={currentImage}
          alt={foodName}
          className="img-fluid"
          referrerPolicy="no-referrer"
        />
        {images.length > 1 && (
          <Button
            className="arrow-button right-arr"
            onClick={onNext}
          >
            <FontAwesomeIcon
              icon={faForward}
              className="ms-2"
              style={{ fontSize: "25px", color: "#000000" }}
            />
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ImageViewerModal;
