import axios from "axios";
import {environment} from "../environments/environment";

const API_BASE_URL = environment.baseUrl;

const api = axios.create({
    baseURL: API_BASE_URL,
});

export const getActivePos = async () => {
    try {
        const response = await api.get("/foo-m/menu-group/active-pos?$filter=isAvailableForCustomer eq true");
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};

export const getItemCategorie = async () => {
    try {
        const response = await api.get("/foo-m/food-category");
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};


export const getPlaceholderImage = async () => {
    try {
        const response = await api.get("/rpo-m/system-settings/app-image/food-template");
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
}

export const getItemType = async () => {
    try {
        const response = await api.get("/foo-m/food-type");
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};

export const getSearchTag = async () => {
    try {
        const response = await api.get("/foo-m/food-item-search-tag");
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};

export const createDineInOrder = async (data) => {
    try {
        const response = await api.post("/rpo-m/customer-order/dine-in", data);
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};

export const createTakeAwayOrder = async (data) => {
    try {
        const response = await api.post("/rpo-m/customer-order/take-away", data);
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};

export const createDeliveryOrder = async (data) => {
    try {
        const response = await api.post("/rpo-m/customer-order/delivery", data);
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};

export const getFoodItemQuantity = async () => {
    try {
        const response = await api.get("/foo-m/food-quantity-tag");
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};

export const getLandingPage = async () => {
    try {
        const response = await api.get("rpo-m/system-settings/customer-app-image/banners");
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};

export const getPopularDeals = async () => {
    try {
        const response = await api.get("rpo-m/system-settings/customer-app-image/offers");
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};


export const getOrderById = async (orderId) => {
    try {
        const response = await api.get(`rpo-m/cart/order/${orderId}`);
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};

export const getFoodItem = async () => {
    try {
        const response = await api.get("foo-m/food");
        return response.data;
    } catch (error) {
        alert(error.response.data.message)
        throw error;
    }
};
