import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, InputNumber, Form, Select } from "antd";
import { addFoodToCart } from "../../../../store/PlaceOrderActions";
import "./AddToCartModel.css";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const AddToCartModel = ({ open, onClose, foodItem, quantityTagKeyValue }) => {
  const [form] = Form.useForm();
  const [selectedExtras, setSelectedExtras] = useState([]);

  const filteredQuantityTags = quantityTagKeyValue.filter((tag) =>
    foodItem?.foodQuantityTagIds?.includes(tag.value)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (open && filteredQuantityTags.length > 0) {
      form.setFieldsValue({ foodQuantityTagId: filteredQuantityTags[0].value });
    } else {
      form.resetFields();
    }
  }, [open, form, filteredQuantityTags]);

  const handleAddToCart = () => {
    form.validateFields().then((values) => {
      const cartItem = {
        name: foodItem?.foodName,
        foodId: foodItem?.foodId,
        menuId: foodItem?.menuFoodId,
        kitchenId: foodItem?.kitchenId,
        quantity: parseInt(values.quantity, 10),
        kitchenIntroduction: values.kitchenInstruction || "",
        price: foodItem?.price * filteredQuantityTags.find(x => x.value === values.foodQuantityTagId).priceMultiply,
        extras: selectedExtras,
        foodQuantityTagId: values.foodQuantityTagId || "",
        totalCost: foodItem?.totalCost,
      };
      dispatch(addFoodToCart(cartItem));
      resetForm();
      onClose();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const resetForm = () => {
    setSelectedExtras([]);
    form.resetFields();
  };

  const onCancel = () => {
    resetForm();
    onClose();
  };

  const handleExtraChange = (extraId) => {
    setSelectedExtras((prev) =>
      prev.includes(extraId)
        ? prev.filter((id) => id !== extraId)
        : [...prev, extraId]
    );
  };

  return (
    <Modal
      title={foodItem?.foodName}
      open={open}
      centered
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleAddToCart}>
          Add to Cart
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleAddToCart}
        onFinishFailed={onFinishFailed}
        initialValues={{ quantity: 1 }}
      >
        <Form.Item
          label="Quantity"
          name="quantity"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            { required: true, message: "Please enter Quantity!" },
            {
              validator: (_, value) =>
                value >= 1
                  ? Promise.resolve()
                  : Promise.reject(new Error("Quantity must be at least 1")),
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} min={1} />
        </Form.Item>
        <Form.Item
          label="Select Size"
          name="foodQuantityTagId"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: "Please select a size!" }]}
        >
          <Select placeholder="Select Size" style={{ width: "100%" }}>
            {filteredQuantityTags.map((tag) => (
              <Option key={tag.value} value={tag.value}>
                {tag.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="row">
          {foodItem?.foodExtras?.map((extra) => (
            <div key={extra.id} className="col-4 mb-2">
              <div
                className={`image-container1 cursor ${
                  selectedExtras.includes(extra.id) ? "selected" : ""
                }`}
                onClick={() => handleExtraChange(extra.id)}
              >
                <img
                  src={extra.imageUrl}
                  alt="extra"
                  className="extra-image"
                  style={{ width: "100%", height: "auto" }}
                />
                <div
                  className={`price-label ${
                    selectedExtras.includes(extra.id) ? "selected-label" : ""
                  }`}
                >
                  {extra.name}
                </div>
              </div>
            </div>
          ))}
        </div>

        <Form.Item
          label="Kitchen Instruction"
          name="kitchenInstruction"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="mt-3"
        >
          <TextArea
            placeholder="Enter kitchen instruction"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddToCartModel;
