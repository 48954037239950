// src/store/store.js

import { configureStore } from '@reduxjs/toolkit';
import { placeOrderReducer } from './PlaceOrderReducer';

export const store = configureStore({
  reducer: {
    placeOrder: placeOrderReducer
  }
});


