import { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import {orderStatusSubject} from "../subjects/custome-subject";

export const useSignalR = (hubUrl) => {
    const [connection, setConnection] = useState(null);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        // Create a connection
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl)
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        setConnection(newConnection);

        return () => {
            if (newConnection) {
                newConnection.stop();
            }
        };
    }, [hubUrl]);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    console.log(`SignalR connection success! connectionId: ${connection.connectionId} `);

                    // Example: Listen for a specific message from the server
                  setSignalrClientMethods();
                })
                .catch((err) => console.log('Connection failed: ', err));
        }
    }, [connection]);
    const setSignalrClientMethods = () => {
        if (connection) {
            connection.on('orderStatusNotificationMessage', ( message) => {
                orderStatusSubject.sendOrderStatus(message);
                setMessage( message);
            });
        }
    }
};

