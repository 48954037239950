import useDeviceDetect from "../../../hooks/useDeviceDetect";
import React, {useState, useEffect } from "react";
import { Tabs } from "antd";
import DineIn from "../Content/DineIn/DineIn";
import Takeaway from "../Content/Takeaway/Takeaway";
import Delivery from "../Content/Delivery/Delivery";
import { faPersonBiking } from "@fortawesome/free-solid-svg-icons";
import { faBurger } from "@fortawesome/free-solid-svg-icons";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import {getLandingPage } from "../../../services/apiBaseService";
import { Watch } from "react-loader-spinner";

export default function Content({ selectedMenuId }) {
  const device = useDeviceDetect();
  const [landingPage, setLandingPage] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const onChange = (key) => {
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  let tableId = query.get('tableId');

  useEffect(() => {
    getLandingPages()
    if (selectedMenuId) {
    }
  }, [selectedMenuId]);

  const getLandingPages = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const response = await getLandingPage();
      if (response && response.length > 0) {
        const imageUrl = response[0]?.customerAppImages?.imageUrl;
        setLandingPage(imageUrl); 
      } else {
        console.log("No landing pages found");
      }
    } catch (error) {
      console.error("Error fetching landing page", error);
    }finally {
      setIsLoading(false); // Set loading state to false
    }
  };
  

  return (
    <div>
      {isLoading ? (
        <div className="loader-overlay">
          <Watch
            visible={true}
            height="80"
            width="80"
            radius="48"
            color="#4fa94d"
            ariaLabel="watch-loading"
          />
        </div>
      ) : device?.isMobile ? (
        <>
          <div>
            <img
              src={landingPage}
              alt="cover"
              style={{
                width: "100%",
                height: "227px",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100vw",
            }}
          >
            <div
              style={{
                width: "90%",
                marginTop: "-80px",
                border: "1px solid #ccc",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                backgroundColor: "#fff",
              }}
            >
              <Tabs
                onChange={onChange}
                type="card"
                centered
                items={[
                  {
                    label: "Dine-In",
                    key: "1",
                    children: <DineIn seatedTableId={tableId}  />,
                    icon: <FontAwesomeIcon icon={faUtensils} />,
                  },
                  {
                    label: "Takeaway",
                    key: "2",
                    children: <Takeaway selectedMenuId={selectedMenuId} />,
                    icon: <FontAwesomeIcon icon={faBurger} />,
                  },
                  {
                    label: "Delivery",
                    key: "3",
                    children: <Delivery selectedMenuId={selectedMenuId} />,
                    icon: <FontAwesomeIcon icon={faPersonBiking} />,
                  },
                ]}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <img
              src={landingPage}
              alt="cover"
              style={{
                width: "100%",
                height: "40vh",
                objectFit: "cover"
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100vw",
            }}
          >
            <div
              style={{
                width: "45%",
                marginTop: "-100px",
                border: "1px solid #ccc",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                backgroundColor: "#fff",
              }}
            >
              <Tabs
                onChange={onChange}
                type="card"
                centered
                items={[
                  {
                    label: "Takeaway",
                    key: "1",
                    children: <Takeaway selectedMenuId={selectedMenuId} />,
                    icon: <FontAwesomeIcon icon={faBurger} />,
                  },
                  {
                    label: "Delivery",
                    key: "2",
                    children: <Delivery selectedMenuId={selectedMenuId} />,
                    icon: <FontAwesomeIcon icon={faPersonBiking} />,
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
