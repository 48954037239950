import "./App.css";
import HomePage from "./pages/HomePage";
import LandingPage from "./pages/LandingPage";

import Router from "./routes/router";

function App() {
  return <Router />;
}

export default App;
