import useDeviceDetect from "../../../../hooks/useDeviceDetect";
import React from "react";
import { Button, Form, Input, InputNumber } from "antd";
import { useNavigate } from "react-router-dom";
import { addUserDetails } from "../../../../store/PlaceOrderActions";
import { useDispatch } from "react-redux";

export default function Delivery({ selectedMenuId }) {
  const device = useDeviceDetect();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const userDetails = {
      name: values.name,
      telephoneNo: values.telephoneNo,
      address: values.firstLane + ", " + values.secondLane + ", " + values.city,
      tableId: null,
      orderType: "DELIVERY",
    };
    dispatch(addUserDetails(userDetails));
    navigation(`/order?selectedMenuId=${selectedMenuId}`);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      {device?.isMobile ? (
        <>
          <div className="m-3">
            <div
              style={{
                fontSize: "14px",
                fontWeight: "300",
              }}
            >
              Enter your details.
            </div>
            <Form
              form={form}
              className="p-2"
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="telephoneNo"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: /^[0-9\b]+$/,
                    message: "Phone number must contain only digits.",
                  },
                  {
                    len: 10, 
                    message: "Phone number must be exactly 10 digits.",
                  },
                ]}
              >
                <Input placeholder="Enter your phone number" />
              </Form.Item>

              <Form.Item
                name="firstLane"
                label="1st lane"
                rules={[
                  {
                    required: false,
                    message: "Please input your 1st lane!",
                  },
                ]}
              >
                <Input placeholder="Enter first lane" />
              </Form.Item>

              <Form.Item
                name="secondLane"
                label="2nd lane"
                rules={[
                  {
                    required: false,
                    message: "Please input your 2nd lane!",
                  },
                ]}
              >
                <Input placeholder="Enter second lane" />
              </Form.Item>

              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: false,
                    message: "Please input your city!",
                  },
                ]}
              >
                <Input placeholder="Enter city" />
              </Form.Item>

              <Form.Item style={{ textAlign: "right" }}>
                <Button
                  type="default"
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#006D5B",
                    borderColor: "#006D5B",
                  }}
                  htmlType="submit"
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      ) : (
        <>
          <div className="m-3">
            <div
              style={{
                fontSize: "14px",
                fontWeight: "300",
              }}
            >
              Enter your details.
            </div>
            <Form
              form={form}
              className="p-2"
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="telephoneNo"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: /^[0-9\b]+$/,
                    message: "Phone number must contain only digits.",
                  },
                  {
                    len: 10, 
                    message: "Phone number must be exactly 10 digits.",
                  },
                ]}
              >
                <Input placeholder="Enter your phone number" />
              </Form.Item>

              <Form.Item
                name="firstLane"
                label="1st lane"
                rules={[
                  {
                    required: false,
                    message: "Please input your 1st lane!",
                  },
                ]}
              >
                <Input placeholder="Enter first lane" />
              </Form.Item>

              <Form.Item
                name="secondLane"
                label="2nd lane"
                rules={[
                  {
                    required: false,
                    message: "Please input your 2nd lane!",
                  },
                ]}
              >
                <Input placeholder="Enter second lane" />
              </Form.Item>

              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: false,
                    message: "Please input your city!",
                  },
                ]}
              >
                <Input placeholder="Enter city" />
              </Form.Item>

              <Form.Item style={{ textAlign: "right" }}>
                <Button
                  type="default"
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#006D5B",
                    borderColor: "#006D5B",
                  }}
                  htmlType="submit"
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
