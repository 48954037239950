import React, { useState, useEffect } from "react";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import { getActivePos } from "../../../services/apiBaseService";
import { Watch } from "react-loader-spinner";

export default function LandingFooter({ onMenuClick }) {
  const [menusWithFoods, setMenusWithFoods] = useState([]);
  const device = useDeviceDetect();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const data = await getActivePos();
      groupFoodsByMenuId(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const groupFoodsByMenuId = (data) => {
        const menuMap = [];
        data.forEach((x) => {
            let menu = menuMap.find((menu) => menu.menuId === x.menuId);

            // If the menu doesn't exist in the menuMap, create and add it
            if (!menu) {
                menu = {
                    menuId: x.menuId,
                    menuName: x.menuName,
                    menuGroupId: x.menuGroupId,
                    foods: [],
                };
                menuMap.push(menu);
            }

        //     const foodCategoryName = categoryKeyValue.find(
        //         (y) => y.value === x.foodCategoryId
        //     )?.label;
        //     // Find the category within the current menu
        //     let category = menu.foods.find(
        //         (c) => c.categoryName === foodCategoryName
        //     );

        //     // If the category doesn't exist, create it
        //     if (!category) {
        //         category = {
        //             categoryName: foodCategoryName,
        //             categoryFoods: [],
        //         };
        //         menu.foods.push(category);
        //     }

        //     // Add the food to the category's food array
        //     category.categoryFoods.push({
        //         ...x,
        //         imageUrls: x.foodImageUrl == null ? null : x.foodImageUrl.split(","),
        //     });
        });

        // Optionally, sort the categories and their foods if necessary
        menuMap.forEach((menu) => {
            // Sorting categories by name (optional)
            menu.foods.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

            // Sorting the foods within each category (optional)
            menu.foods.forEach((category) => {
                category.categoryFoods.sort((a, b) =>
                    a.foodName.localeCompare(b.foodName)
                );
            });
        });
        const menusArray = Array.from(menuMap.values());
        setMenusWithFoods(menusArray);
    };
  

  const handleMenuClick = (menuId) => {
    alert("Please enter your login details.");
    if (onMenuClick) {
      onMenuClick(menuId);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="loader-overlay">
          <Watch
            visible={true}
            height="80"
            width="80"
            radius="48"
            color="#4fa94d"
            ariaLabel="watch-loading"
          />
        </div>
      ) : device?.isMobile ? (
        <div className="bg-dark text-white pt-3 pb-3 ps-3 pe-3">
          <div className="row">
            <div className="col-6">
              <div>
                <div>
                  <div
                    style={{
                      fontSize: "18.9px",
                      fontWeight: "600",
                    }}
                  >
                    Order Now
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontSize: "12.8px",
                      fontWeight: "400",
                    }}
                  >
                    {menusWithFoods.map((menu) => (
                      <div
                        key={menu.menuId}
                        onClick={() => handleMenuClick(menu.menuId)}
                        style={{ cursor: "pointer" }}
                      >
                        {menu.menuName}
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div>
                <div>
                  <div
                    style={{
                      fontSize: "18.9px",
                      fontWeight: "600",
                    }}
                  >
                    About
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontSize: "12.8px",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    About Us
                    <br />
                    <a
                      href="tel:+94777079079"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      Hotline
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6">
              <div>
                <div>
                  <div
                    style={{
                      fontSize: "18.9px",
                      fontWeight: "600",
                    }}
                  >
                    Visit us
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontSize: "12.8px",
                      fontWeight: "400",
                    }}
                  >
                    Locate a store
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div>
                <div>
                  <div
                    style={{
                      fontSize: "18.9px",
                      fontWeight: "600",
                    }}
                  >
                    Policy
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontSize: "12.8px",
                      fontWeight: "400",
                    }}
                  >
                    Terms & Conditions
                    <br />
                    Privacy Policy
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="text-center mt-5">Find Us on Social Media</div>
            <div className="mt-2 mb-2">
              <img
                className="me-2"
                src="https://img.icons8.com/?size=40&id=118467&format=png&color=FFFFFF"
              ></img>
              <img
                className="me-2"
                src="https://img.icons8.com/?size=40&id=59813&format=png&color=FFFFFF"
              ></img>
              <a
                href="https://wa.me/+94777079079"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://img.icons8.com/?size=40&id=16712&format=png&color=FFFFFF"
                  alt="WhatsApp Icon"
                />
              </a>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              ® 2024 Cube360,All rights reserved.
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-dark text-white pt-4 pb-4 ps-5 pe-5">
          <div className="row">
            <div className="col-3">
              <div>
                <div>
                  <div
                    style={{
                      fontSize: "18.9px",
                      fontWeight: "600",
                    }}
                  >
                    Order Now
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontSize: "12.8px",
                      fontWeight: "400",
                    }}
                  >
                    {menusWithFoods.map((menu) => (
                      <div
                        key={menu.menuId}
                        onClick={() => handleMenuClick(menu.menuId)}
                        style={{ cursor: "pointer" }}
                      >
                        {menu.menuName}
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div>
                <div>
                  <div
                    style={{
                      fontSize: "18.9px",
                      fontWeight: "600",
                    }}
                  >
                    About
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontSize: "12.8px",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    About Us
                    <br />
                    <a
                      href="tel:+94777079079"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      Hotline
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div>
                <div>
                  <div
                    style={{
                      fontSize: "18.9px",
                      fontWeight: "600",
                    }}
                  >
                    Visit us
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontSize: "12.8px",
                      fontWeight: "400",
                    }}
                  >
                    Locate a store
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div>
                <div>
                  <div
                    style={{
                      fontSize: "18.9px",
                      fontWeight: "600",
                    }}
                  >
                    Policy
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontSize: "12.8px",
                      fontWeight: "400",
                    }}
                  >
                    Terms & Conditions
                    <br />
                    Privacy Policy
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="text-center mt-5">Find Us on Social Media</div>
            <div className="mt-2 mb-2">
              <img
                className="me-2"
                src="https://img.icons8.com/?size=40&id=118467&format=png&color=FFFFFF"
              ></img>
              <img
                className="me-2"
                src="https://img.icons8.com/?size=40&id=59813&format=png&color=FFFFFF"
              ></img>
              <a
                href="https://wa.me/+94777079079"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://img.icons8.com/?size=40&id=16712&format=png&color=FFFFFF"
                  alt="WhatsApp Icon"
                />
              </a>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              ® 2024 Cube360,All rights reserved.
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
